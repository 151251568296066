import '../../node_modules/flickity/dist/flickity.min.css'
import '../scss/font.scss'
import '../scss/main.scss'

import $ from 'jquery'
import Flickity from 'flickity'
import 'flickity-imagesloaded'

$(document).ready( () => {
  new Flickity(document.querySelector('.header-slider'), {
    autoPlay: 4500,
    wrapAround: true,
    pageDots: false,
    pauseAutoPlayOnHover: false,
    prevNextButtons: true,
    adaptiveHeight: true,
    imagesLoaded: true,
  })

  $('.impressum').on('click', () => {
    $('html, body').animate({scrollTop:$(document).height()}, 'slow')
    $('.impressum-container').toggle()
    return false
  })

  $('.location').on('click', () => {
    $('html, body').animate({ scrollTop:$('a[href="#location"]').offset().bottom }, 1000)
  })

  $('.overlay .close').on('click', () => {
    $('.order-books.overlay').hide()
    $('.overlay-shadow').hide()
  })
})
